import React from "react";
import {
    BrowserRouter,
    Switch,
    Route
} from "react-router-dom";
import { FrontPage } from "../../Pages/FrontPage/FrontPage";
import { Menu } from "../../Components/Menu/Menu";
import { PageWrapper } from "../PageWrapper/PageWrapper";
import { Footer } from "../../Components/Footer/Footer";
import { Contact } from "../../Components/Contact/Contact";


interface IProps {

}

export function Router(props: IProps) {
    return (
        <BrowserRouter>
            <Menu />
            <div className="router-wrapper">
                <Switch>
                    <Route path="/:slug" children={<PageWrapper />} />
                    <Route path="/">
                        <FrontPage />
                    </Route>
                </Switch>
            </div>
            <Contact/>
            <Footer/>
        </BrowserRouter>
    )
}