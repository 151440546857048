import React, { useRef } from "react";
import "./Portfolio.scss";
import {Projects} from "./Projects/Projects";
import OnVisible from "react-on-visible";
import { Content } from "../Content/Content";

export function Portfolio() {
    const moveSplashUp = (visible: boolean) => {
        const splash = document.getElementsByClassName('splash-wrapper');
        if (visible && splash) {
            const elm = splash.item(0) as HTMLDivElement;

            elm.classList.add('moved');
        }

    };

    return (
        <OnVisible className={"portfolio-wrapper"} onChange={moveSplashUp} percent={100}>
            <Content className={"no-before-no-after"}>
                <div className="intro bold-font">
                    <h1>Portfolio</h1>
                    <p>See what we achieved so far.</p>
                </div>
                <Projects/>
            </Content>
        </OnVisible>
    );
}