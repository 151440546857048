import React, { useEffect, useState } from "react";
import "./PageWrapper.scss";
import { useParams } from "react-router-dom";
import { Content } from "../../Components/Content/Content";
import { Splash } from "../../Components/Splash/Splash";
import { wp } from "../../Factories/Wordpress";
import { IPage } from "squde-wp-api/IPage";
import ScrollToTopOnMount from "../ScrollToTopOnMount/ScrollToTopOnMount";

interface IProps {

}

export function PageWrapper(props: IProps) {
    let { slug } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [page, setPage] = useState<IPage|undefined|null>();

    useEffect(() => {
        wp().page.get({slug}).then((result: IPage) => {
            setPage(result);
            setIsLoading(false);
        })
    }, [slug]);

    const content = (() => {
        if (page) {
            return (
                <>
                    <h1 className={"page-title"}>{page.title}</h1>
                    <div dangerouslySetInnerHTML={{__html: page.content}} />
                </>
            )
        }
        if (isLoading) {
            return (
                <h1 className="page-title">Loading...</h1>
            );
        }
        return (
            <>
                <h1 className={"page-title"}>Whoops!</h1>
                <p style={{textAlign: 'center'}}>This page can not be found...</p>
            </>
        )
    })();

    return (
        <>
            <ScrollToTopOnMount/>
            <Splash small={true}/>
            <Content>

                <div className="page-wrapper">
                    {content}
                </div>
            </Content>
        </>
    )
}