import "./Content.scss";
import React from "react";

interface IProps {
    children: any
    className?: string
}

export function Content(props: IProps) {
    const classes = ["content-wrapper"];

    if (props.className) {
        classes.push(props.className);
    }

    return (
        <div className={classes.join(' ')}>
            {props.children}
        </div>
    )
}