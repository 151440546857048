import React from "react";
import "./MenuButton.scss";

interface IProps {
    onClick: any,
    menuOpen: boolean,
}
interface IState {

}

export class MenuButton extends React.Component<IProps, IState> {
    get className() {
        const classes = ["menu-button-wrapper"];

        if (this.props.menuOpen) {
            classes.push('open');
        }

        return classes.join(' ');
    }
    render() {
        return (
            <div className={this.className} onClick={this.props.onClick}>
                <button className="menu-button"><span /><span /><span /><span /></button>
            </div>
        );
    }
}