import React from "react";
import "./Contact.scss";
import {Form} from "../../Containers/Form/Form";
import {Field} from "../../Containers/Field/Field";
import {PhoneInput} from "../PhoneInput/PhoneInput";
import {wp} from "../../Factories/Wordpress";
import ReactGA from 'react-ga';

interface IProps {

}
interface IState {
    isLoading: boolean,
    done: boolean,
}

export class Contact extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: false,
            done: false
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleFormSubmit(data: any) {
        this.setState({isLoading: true});
        wp().submissions.post(data).then((answer: any) => {
            this.setState({isLoading: false, done: true});
            ReactGA.event({
                category: 'Contact',
                action: 'Submitted contact form'
            });
        });
    }
    handleFormFocus() {
        ReactGA.event({
            category: 'Contact',
            action: 'Touched contact form'
        });
    }
    render() {
        return (
            <div className="contact-wrapper">
                <div className={"intro white full " + (this.state.isLoading ? 'loading' : (this.state.done ? 'done' : ''))}>
                    <div className="form-wrapper" onClick={this.handleFormFocus} onTouchStart={this.handleFormFocus}><div className="bold-font">
                        <h1>Convinced yet?</h1>
                        <p>Reach out to us!</p>
                    </div>
                        <Form onSubmit={this.handleFormSubmit} name={"contact"}>
                            <Field name="name" title="Name" inputType="text" required={true} />
                            <PhoneInput name="phone" title="Phone number" />
                            <Field name="email" title="Email" inputType="text" />
                            <Field name="message" title="Leave a message" inputType="textarea" />
                            <p className="small-text"><em>By sending this form you also agree with our terms.</em></p>
                        </Form>
                    </div>
                    <div className="loading-wrapper">
                        <div className="bold-font">
                            <h1>Wait for it...</h1>
                        </div>
                        <div className="loading-indicator" />
                    </div>
                    <div className="done-wrapper">
                        <div className="bold-font">
                            <h1>Thank you!</h1>
                            <p>You will hear from us very soon.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}