import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {MenuButton} from "./MenuButton/MenuButton";
import "./Menu.scss";

export function Menu() {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    let history = useHistory();

    const toggleMenuButton = () => {
        setMenuOpen(!menuOpen);
    };

    const menuClass = (() => {
        const classes = ['menu-wrapper'];
        if (menuOpen) {
            classes.push('show');
        }
        return classes.join(' ');
    })();

    const scrollTo = (selector?: string) => {
        const slug = (() => {
            const p = window.location.href.toString().split('://')[1].split('/');
            return p.length === 1 ? '' : p[1];
        })();
        let timeout = 0;
        if (slug.length > 0) {
            history.push("/");
            timeout = 100;
        }

        setTimeout(() => {
            let top = 0;
            if (selector) {
                const elm = (document.querySelectorAll(selector.toString())[0] as HTMLDivElement);
                if (elm) {
                    top = elm.offsetTop;
                }
            }
            window.scrollTo({top, behavior: "smooth"});
            toggleMenuButton();
        }, timeout);
    };

    return (
        <>
            <MenuButton onClick={toggleMenuButton} menuOpen={menuOpen} />
            <div className={menuClass}>
                <div className="menu-container bold-font">
                    <h1>Menu</h1>
                    <ul>
                        <li><button onClick={() => {scrollTo()}}>Home</button></li>
                        <li><button onClick={() => {scrollTo('.portfolio-wrapper')}}>Portfolio</button></li>
                        <li><button onClick={() => {scrollTo('.contact-wrapper')}}>Contact</button></li>
                    </ul>
                </div>
            </div>
        </>
    );
}