import React from "react";
import "./FrontPage.scss";
import {Splash} from "../../Components/Splash/Splash";
import {Portfolio} from "../../Components/Portfolio/Portfolio";

export function FrontPage() {
    return (
        <>
            <div className="front-page">
                <Splash/>
                <Portfolio/>
            </div>
        </>
    );
}