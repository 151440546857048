import React from 'react';
import ReactGA from 'react-ga';
import { Router } from "./Containers/Router/Router";

ReactGA.initialize('UA-155236646-1');
ReactGA.pageview('/');

const App: React.FC = () => {
  return (
    <div className="App">
        <Router/>
    </div>
  );
};

export default App;
