import React, { useEffect, useRef } from "react";
import "./Splash.scss";
import {SubTitle} from "./SubTitle/SubTitle";

interface IProps {
    small?: boolean
}

export function Splash(props: IProps) {
    const className = (() => {
        const classes = ['splash-wrapper'];
        if (props.small) {
            classes.push('small');
        }
        return classes.join(' ');
    })();

    const ref = useRef(null);
    const splash = useRef(null);
    useEffect(() => {

        if (ref.current) {
            const elm = ref.current as any as HTMLDivElement;
            const onHover = (e: MouseEvent) => {
                const splashElm = (splash.current as any as HTMLDivElement);

                const {
                    pageX,
                    pageY
                } = e;

                const {
                    offsetLeft,
                    offsetTop,
                    clientWidth,
                    clientHeight
                } = splashElm;

                let relX = pageX - offsetLeft;
                let relY = pageY - offsetTop;

                const offsetMinX = clientWidth;
                const offsetMinY = clientHeight;
                const currentX = relX + offsetMinX * -0.25;
                const currentY = relY + offsetMinY * -0.25;
                const newX = currentX / 1000000;
                const newY = currentY / 1000000;

                const tX = relX / window.innerWidth * 25;
                const tY = relY / window.innerHeight * 25;
                const transforms = [
                    'translate(calc(-50% - ' + tX + '%), calc(-50% - ' + tY + '%))',
                    'matrix3d(1.025,0,0,'+ newX.toString() + ",0,1.025,0," + newY.toString() + ",0,0,1,0,0,0,0,1)"
                ];
                splashElm.setAttribute('style', 'transform: ' + transforms.join(' '));

            };
            elm.addEventListener('mousemove', onHover);

            const onLeave = () => {
                const splashElm = (splash.current as any as HTMLDivElement);
                splashElm.removeAttribute('style');
            };
            elm.addEventListener('mouseout', onLeave);

            return () => {
                elm.removeEventListener('mousemove', onHover);
                elm.removeEventListener('mouseout', onLeave);
            }
        }
    }, [ref]);


    return (
        <div className={className} ref={ref}>
            <div className="splash bold-font" ref={splash}>
                <h1 className="primary">
                    <span>Dude,</span>
                    <span>just...</span>
                    <span>SQUDE.</span>
                </h1>
                <SubTitle />
            </div>
        </div>
    );
}