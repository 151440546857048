import React from "react";
import "./Footer.scss";
import {
    NavLink
} from "react-router-dom";
import { wp } from "../../Factories/Wordpress";
import { IPost } from "squde-wp-api/IPost";
import { IPage } from "squde-wp-api/IPage";

interface IProps {

}
interface IState {
    clients: any[]
    pages: any[]
}

export class Footer extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            clients: [],
            pages: []
        };
    }
    get clientsList() {
        return this.state.clients.map((client: any, index: number) => {
            return (
                <li key={index}>
                    <a href={client.link} target="_blank" rel="noopener noreferrer">{client.name}</a>
                </li>
            );
        });
    }
    get infoList() {
        if (!this.state.pages || this.state.pages.length === 0) {
            return null;
        }
        return (
            <div>
                <h3 className="bold-font">Information (Dutch)</h3>
                <ul>
                    {(() => {
                        return this.state.pages.map((page: any, index: number) => {
                            return (
                                <li key={index}>
                                    <NavLink to={page.link}>{page.name}</NavLink>
                                </li>
                            );
                        });
                    })()}
                </ul>
            </div>
        );
    }
    get legalList() {
        return [
            {
                'name': 'Terms & Conditions',
                'link': 'https://squde.nl/algemene-voorwaarden.pdf',
            },
            {
                'name': 'Privacy',
                'link': 'https://squde.nl/privacy-statement.pdf',
            },
            {
                'name': 'KvK / CoC: 65270096',
            },
            {
                'name': 'BTW / TAX: NL002260403B62',
            },
            {
                'name': 'Copyright ' + (new Date()).getFullYear() + ' - Squde',
            },
        ].map((listItem: any, index: number) => {
            const html = (
                !!listItem.link ? <a href={listItem.link} target="_blank" rel="noopener noreferrer" download>{listItem.name}</a> : <span>{listItem.name}</span>
            );
            return (
                <li key={index}>
                    {html}
                </li>
            );
        });
    }
    get contactList() {
        return [
            {
                'name': 'Squde',
            },
            {
                'name': 'Ridderspoorlaan 37',
            },
            {
                'name': '8255 JC Swifterbant',
            },
            {
                'name': 'Netherlands',
            },
            {
                'name': 'info@squde.nl',
            },
            {
                'name': '06 - 11 34 68 33',
            },
        ].map((listItem: any, index: number) => {
            const html = (
                !!listItem.link ? <a href={listItem.link}>{listItem.name}</a> : <span>{listItem.name}</span>
            );
            return (
                <li key={index}>
                    {html}
                </li>
            );
        });
    }

    componentDidMount(): void {
        this.retrieve();
    }

    retrieve() {
        (wp().posts.get() as Promise<IPost[]>).then((posts: IPost[]) => {
            const clients = posts.map((post: IPost) => {
                return {
                    name: post.title,
                    link: post.acf.url
                };
            }).sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1);
            this.setState({clients});
        });

        (wp().pages.get() as Promise<IPage[]>).then((result: IPage[]) => {
            const pages = result
                .filter((page: IPage) => page.title !== 'Home')
                .map((page: IPage) => {
                    return {
                        name: page.title,
                        link: (() => {
                            return page.url.split('/new/').join('/');
                        })()
                    };
            }).sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1);
            this.setState({pages});
        });
    }

    render() {
        return (
            <div className="footer-wrapper">
                <div>
                    <h3 className="bold-font">Happy clients</h3>
                    <ul>
                        {this.clientsList}
                    </ul>
                </div>
                {this.infoList}
                <div>
                    <h3 className="bold-font">Contact us</h3>
                    <ul>
                        {this.contactList}
                    </ul>
                </div>
                <div>
                    <h3 className="bold-font">Legal</h3>
                    <ul>
                        {this.legalList}
                    </ul>
                </div>
            </div>
        );
    }
}
