import React, { useEffect, useRef } from "react";
import { Image } from "../../../Image/Image";

interface IProps {
    project: any
    tapped?: boolean
    onClick: (event: any) => void
    index: number
}

export function Project(props: IProps) {
    const classes = ["project"];
    if (props.tapped) {
        classes.push('tapped');
    }

    const { project } = props;
    const ref = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        const onScroll = () => {


            if (ref && ref.current && imageRef && imageRef.current) {
                const elm = ref.current as any as HTMLDivElement;

                const img = imageRef.current as any as HTMLDivElement;
                const calc = elm.getBoundingClientRect().top / elm.clientHeight * -100 / 10;

                img.setAttribute('style', 'transform: translateY(' + calc + '%) scale(1.2)')

            }
        };
        document.addEventListener('scroll', onScroll);

        return () => {
            document.removeEventListener('scroll', onScroll);
        }
    }, [ref, imageRef]);

    return (
        <div className={classes.join(' ')} ref={ref}>
            <div className="on-hover">
                <h2 className="bold-font">{project.title}</h2>
                <div dangerouslySetInnerHTML={project.content} />
                <button className="button hollow white" onClick={props.onClick}>
                    Visit site
                </button>
            </div>
            <div className="image-wrapper" ref={imageRef}>
                <Image id={project.mediaId} priority={(props.index + 1)}/>
            </div>
        </div>
    );
}