import React from "react";
import "./Projects.scss";
import { IPost } from "squde-wp-api/IPost";
import { wp } from "../../../Factories/Wordpress";
import { Image } from "../../Image/Image";
import { Project } from "./Project/Project";

interface IProps {

}
interface IState {
    projects: any,
    activeSlide: number,
    activeProjectSlide: number,
    hasMore: boolean,
    isDragging: boolean,
    startPosition: number,
    stopPosition: number,
    dragX: number,
    tappedProject?: number,
    debug?: any,
}
export class Projects extends React.Component<IProps, IState> {
    public elm: HTMLDivElement|null = null;

    constructor(props: IProps) {
        super(props);

        this.state = {
            projects: [],
            activeSlide: 0,
            activeProjectSlide: 0,
            hasMore: true,
            isDragging: false,
            startPosition: 0,
            stopPosition: 0,
            dragX: 0,
        };

        this.goToNext = this.goToNext.bind(this);
        this.goToPrevious = this.goToPrevious.bind(this);
        this.startDragging = this.startDragging.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.stopDragging = this.stopDragging.bind(this);
        this.preventVerticalScroll = this.preventVerticalScroll.bind(this);
    }
    componentDidMount(): void {
        this.retrieve();
        document.addEventListener('touchend', this.stopDragging);
        window.addEventListener('touchmove', this.preventVerticalScroll, {passive: false});
    }
    componentWillUnmount(): void {
        document.removeEventListener('touchend', this.stopDragging);
        window.removeEventListener('touchmove', this.preventVerticalScroll);
    }
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        return JSON.stringify(nextState) !== JSON.stringify(this.state);
    }

    get projects() {
        if (this.state.projects && this.state.projects.length > 0) {
            const groups: any= [];
            let groupContents: any[] = [];
            this.state.projects.map((project: any, index: number) => {
                return <Project
                    key={index}
                    index={index}
                    project={project}
                    tapped={this.state.tappedProject !== undefined && this.state.tappedProject === index}
                    onClick={(event) => { this.handleProjectClick(event, project) }}
                />;
            }).forEach((project: any, index: number) => {
                groupContents.push(project);
                if (groupContents.length === 3) {
                    groups.push(groupContents);
                    groupContents = [];
                }
            });
            if (groupContents.length > 0) {
                groups.push(groupContents);
            }
            return groups.map((group: any, index: number) => {
                return (
                    <div key={index} className={"projects-container" + (index === this.state.activeSlide ? ' show' : '')}>
                        {group}
                    </div>
                );
            });
        }
        return null;
    }

    get dragStyle () {
        if (!this.state.isDragging) {
            return {};
        }
        return {transform: 'translateX(calc(100vw * -' + this.state.activeProjectSlide + ' + ' + this.state.dragX + 'px)'};
    }

    retrieve() {
        (wp().posts.get() as Promise<IPost[]>)
            .then((posts: IPost[]) => {
                const projects = posts.map((post: IPost) => {
                    return {
                        title: post.title,
                        content: {__html: post.content},
                        mediaId: post.featured_media,
                        link: post.acf.url
                    };
                });
                this.setState({projects});
        });
    }

    preventVerticalScroll (e: any) {
        if(this.state.isDragging) { e.preventDefault(); e.returnValue = false; return false; }
    }
    goToNext() {
        if (!this.state.hasMore) {
            return;
        }
        const activeSlide = this.state.activeSlide + 1;
        const hasMore = Math.ceil(this.state.projects.length / 3) >= activeSlide * 3;
        this.setState({activeSlide, hasMore});
    }
    goToPrevious() {
        if (this.state.activeSlide === 0) {
            return;
        }
        const activeSlide = this.state.activeSlide - 1;
        const hasMore = Math.ceil(this.state.projects.length / 3) >= activeSlide * 3;
        this.setState({activeSlide, hasMore});
    }
    slideToNextProject() {
        if (this.state.projects.length === this.state.activeProjectSlide + 1) {
            if (this.state.isDragging) {
                this.setState({isDragging: false});
            }
            return;
        }
        const activeProjectSlide = this.state.activeProjectSlide + 1;
        this.setState({activeProjectSlide, isDragging: false});
    }
    slideToPreviousProject() {
        if (this.state.activeProjectSlide === 0) {
            if (this.state.isDragging) {
                this.setState({isDragging: false})
            }
            return;
        }
        const activeProjectSlide = this.state.activeProjectSlide - 1;
        this.setState({activeProjectSlide, isDragging: false});
    }
    startDragging(event: any) {
        if (event.target.classList.contains('button') || window.outerWidth >= 1024) {
            return;
        }
        if (!this.state.isDragging) {
            this.setState({
                isDragging: true,
                startPosition: event.touches[0].clientX,
                stopPosition: event.touches[0].clientX,
                dragX: 0
            });
        }
    }
    handleDrag(event: any) {
        if (this.state.isDragging) {
            const current = event.touches[0].clientX;
            const dragX = current - this.state.startPosition;
            this.setState({dragX, stopPosition: current, tappedProject: undefined});
        }
    }
    stopDragging() {
        if (this.state.isDragging) {
            const start = this.state.startPosition;
            const end = this.state.stopPosition;
            const direction = start > end ? 'right' : 'left';
            const minimumDiff = window.outerWidth / 100 * 20;
            const diff = (start - end) < 0 ? (start - end) * -1 : (start - end);

            if (diff > minimumDiff) {
                if (direction === 'right') {
                    this.slideToNextProject();
                } else {
                    this.slideToPreviousProject();
                }
            } else {
                const newState: any = {isDragging: false};
                if (diff === 0) {
                    newState.tappedProject = this.state.tappedProject === this.state.activeProjectSlide ? undefined: this.state.activeProjectSlide;
                }

                this.setState(newState);
            }
        }
    }

    handleProjectClick(e: any, project: any) {
        e.stopPropagation();

        window.open(project.link);
    }

    render() {
        return (
            <>
                <div
                    className={"projects-wrapper" + (this.state.isDragging ? ' is-dragging' : '')}
                    data-active-slide={this.state.activeProjectSlide}
                    data-length={this.state.projects.length}
                    onTouchStart={this.startDragging}
                    onTouchMove={this.handleDrag}
                    style={this.dragStyle}
                    ref={(ref) => this.elm = ref}
                >
                    {this.projects}
                </div>
                <div className="projects-toggle-wrapper show-for-large">
                    <button className={"toggle-button bold-font" + (this.state.hasMore ? ' show' : '')} onClick={this.goToNext}>Check out more projects &nbsp;&nbsp;></button>
                    <button className={"toggle-button bold-font" + (this.state.activeSlide > 0 ? ' show' : '')} onClick={this.goToPrevious}>{"<"}&nbsp;&nbsp; Go back</button>
                </div>
                <div className="medium-screens-swipe-indicator hide-for-large">
                    {/*<span className="bold-font">{this.state.debug}</span>*/}
                    <span className="bold-font">Swipe to see more projects</span>
                </div>
            </>
        );
    }
}