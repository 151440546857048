import React, { useEffect, useState } from "react";
import { wp } from "../../Factories/Wordpress";
import { IMedia } from "squde-wp-api/IMedia";

interface IProps {
    id: number
    attachedRef?: any
    priority?: number
}
interface IState {
    alt?: string
    src?: string
    full?: string
    fullyLoaded?: boolean
}


export function Image(props: IProps) {
    const [state, setState] = useState({alt: undefined, src: undefined} as IState);

    const { priority = 10 } = props;

    if (!state.src) {
        wp().image.get(props.id, undefined, priority).then((media: IMedia|null) => {
            if (media) {
                const sizes = media.media_details.sizes;
                const alt = media.alt_text;
                const img = sizes.medium;
                const src = img.source_url;

                setState({alt, src, full: sizes.full.source_url});
            }
        });
    }



    useEffect(() => {
        const loadOnScroll = () => {
            if (state.fullyLoaded) {
                return;
            }
            setState({src: state.full, fullyLoaded: true});
            document.removeEventListener('scroll', loadOnScroll);
        };
        document.addEventListener('scroll', loadOnScroll);

        return () => {
            document.removeEventListener('scroll', loadOnScroll);
        }
    }, [state]);

    return (
        <>
            {state.src ? (
                <img src={state.src} alt={state.alt} ref={props.attachedRef} />
            ) : ''}
        </>
    );
}
