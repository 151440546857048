import React from "react";
import "./SubTitle.scss";

interface IProps {

}
interface IState {
    activeWordIndex: number,
}

export class SubTitle extends React.Component<IProps, IState> {
    public interval: any;

    constructor(props: IProps) {
        super(props);

        this.state = {
            activeWordIndex: 0
        };

        this.slideWords = this.slideWords.bind(this);
    }

    get words() {
        return [
            'build amazing websites',
            'drink coffee',
            'build pwa\'s',
            'create ux',
            'raise your brand',
            'design ui',
            'have lunch',
            'build mobile apps',
            'design your billboard',
            'help you with seo',
            'have weekend.'
        ];
    }

    get wordsList() {
        return this.words.map((words: string, index: number) => {
            const className = [];
            if (index === this.state.activeWordIndex) {
                className.push('active');
            }
            return (
                <li key={index} className={className.join(' ')}>{words}</li>
            );
        });
    }

    componentDidMount(): void {
        this.startInterval();
    }
    componentWillUnmount(): void {
        this.stopInterval();
    }
    startInterval() {
        this.interval = setInterval(this.slideWords, 2000);
    }
    stopInterval() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }
    slideWords() {
        let activeWordIndex = this.state.activeWordIndex + 1;
        if (activeWordIndex === this.words.length) {
            activeWordIndex = 0;
        }
        this.setState({activeWordIndex});
    }

    render() {
        return (
            <div className="white sub-title">
                <span className="we">we </span>
                <ul>
                    {this.wordsList}
                </ul>
            </div>
        );
    }
}